<template>
  <v-card
    :color="cardColor"
    :dark="!scenario.Simulering"
    :loading="loading"
    data-testid="cardScenarioCard"
  >
    <v-card-title class="headline">
      {{ scenario.Navn }}
    </v-card-title>
    <v-card-subtitle v-if="scenario.AvdelingNavn && showDepInfo">
      {{ $t("department") + " " + scenario.AvdelingNavn }}
    </v-card-subtitle>

    <v-card-text id="card-text" v-if="!loading">
      <v-alert v-if="!scenario.Grupper.length" type="error">
        <div class="text-h6">{{ $t("scenarioMissingGroups") }}</div>
        {{ $t("scenarioMissingGroupsInfo") }}
      </v-alert>
      <v-alert v-if="scenario.Simulering" type="warning">
        {{ $t("simScenarioWarning") }}
      </v-alert>

      <h4>{{ $t("groups") }}</h4>
      <v-row class="mt-n2">
        <v-col cols="12" sm="12">
          <span v-for="group in scenario.Grupper" class="group-chip">
            <GroupChip
              :group-id="group.Gruppeid"
              :group-name="group.Navn"
              :permission-to-edit="groupIsEditable(group.Gruppeid)"
            />
          </span>
        </v-col>
      </v-row>

      <div class="card-section mt-2">
        <h4>{{ $t("channels") }}</h4>
        <span v-for="channel in channels">
          <v-chip v-if="channel.enabled" class="chip-channel">
            <v-avatar left>
              <v-icon small>
                {{ channel.icon }}
              </v-icon>
            </v-avatar>
            {{ channel.name }}
          </v-chip>
        </span>
      </div>

      <div class="alert-text-preview card-section">
        <h4>{{ $t("message") }}</h4>
        <i>{{ getTextPreview() }}</i>
      </div>
      <div v-if="responsesDefined">
        <h4>{{ $t("responseOptions") }}</h4>
        <ResponsesPreview :responses="responses" />
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn
        @click="openDetailsDialog"
        icon
        fab
        class="elevation-8"
        data-testid="cogwheelScenarioCard"
      >
        <v-icon>mdi-cog</v-icon>
      </v-btn>
      <v-btn
        @click="confirmStart"
        fab
        color="green"
        data-testid="startScenarioCard"
      >
        <v-icon>mdi-play</v-icon>
      </v-btn>
    </v-card-actions>

    <v-dialog v-model="dialogConfirm" max-width="600" persistent>
      <ScenarioConfirm
        :scenario="scenario"
        :editable-groups="editableGroups"
        v-on:ok="start"
        v-on:cancel="dialogConfirm = false"
      />
    </v-dialog>

    <v-dialog
      v-model="dialogDetails"
      v-if="dialogDetails"
      max-width="1600"
      :fullscreen="$vuetify.breakpoint.xs"
      scrollable
      persistent
    >
      <ScenarioDetails
        :scenario-object="scenario"
        v-on:cancel="closeDetails"
        v-on:save="scenarioSaved"
        v-on:delete="scenarioDeleted"
      />
    </v-dialog>

    <v-dialog v-model="dialogNoPermission" max-width="400">
      <UserError v-on:ok="dialogNoPermission = false">
        <template v-slot:text>
          {{ $t("noDepPermissionScenario") }}
        </template>
      </UserError>
    </v-dialog>
  </v-card>
</template>

<script>
import UserConfirm from "@/components/misc/UserConfirm";
import ScenarioDetails from "@/components/scenario/ScenarioDetails";
import ScenarioConfirm from "@/components/scenario/ScenarioConfirm";
import GroupChip from "@/components/groups/GroupChip";
import apiScenario from "@/api/v24/api.scenario";
import ResponsesPreview from "@/components/composers/ResponsesPreview";
import ScenarioParams from "@/util/ScenarioParams";
import alertResponseOptions from "@/util/alertResponseOptions";
import store from "@/store";
import user from "@/util/user";
import arrayUtil from "@/util/arrayUtil";
import UserError from "@/components/misc/UserError.vue";
import apiGroup from "@/api/v24/api.group";

export default {
  name: "ScenarioCard",
  components: {
    UserError,
    ResponsesPreview,
    GroupChip,
    ScenarioConfirm,
    ScenarioDetails,
    UserConfirm
  },
  props: {
    scenario: {
      type: Object,
      required: true
    },
    editableGroups: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    loading() {
      return this.groups === null && !!store.state.userProfile;
    },

    channels() {
      return [
        {
          name: this.$t("sms"),
          enabled: this.isSmsEnabled(),
          icon: "mdi-cellphone"
        },
        {
          name: this.$t("email"),
          enabled: this.isEmailEnabled(),
          icon: "mdi-email"
        },
        {
          name: this.$t("voice"),
          enabled: this.isVoiceEnabled(),
          icon: "mdi-phone"
        }
      ];
    },

    cardColor() {
      return this.scenario.Simulering ? "#caad24" : "#587FA8";
    },

    responses() {
      return this.scenario.Custom.responses.options;
    },

    responsesDefined() {
      return alertResponseOptions.oneOrMoreDefined(
        this.scenario.Custom.responses.options
      );
    },

    showDepInfo() {
      return store.getters.getShowDepartmentInfoInLists;
    }
  },

  data: () => ({
    dialogDetails: false,
    dialogConfirm: false,
    dialogGroup: false,
    dialogNoPermission: false,
    groupId: null
  }),

  methods: {
    groupIsEditable(group) {
      if (store.getters.getUserIsAdmin) {
        return true;
      } else if (!this.editableGroups.includes(group)) {
        return false;
      } else {
        return store.state.userProfile.Bruker._permissions.manageContacts;
      }
    },

    confirmStart() {
      this.openConfirmDialog();
    },

    start() {
      this.closeConfirmDialog();
      const scenarioParams = new ScenarioParams(this.scenario);
      scenarioParams.getStartScenarioApiParams().then(startParams => {
        apiScenario.start(startParams).then(() => {
          this.$emit("started");
        });
      });
    },

    scenarioSaved() {
      this.closeDetailsDialog();
      this.$emit("save");
    },

    scenarioDeleted() {
      this.closeDetailsDialog();
      this.$emit("delete");
    },
    closeDetails() {
      this.closeDetailsDialog();
      this.$emit("cancel");
    },
    openConfirmDialog() {
      this.dialogConfirm = true;
    },

    closeConfirmDialog() {
      this.dialogConfirm = false;
    },

    openDetailsDialog() {
      this.userCanEdit().then(ok => {
        this.dialogNoPermission = !ok;
        this.dialogDetails = ok;
      });
    },

    userCanEdit() {
      // Checks if the logged in user has access to all groups included in
      // scenario
      return new Promise(resolve => {
        // No restrictions for admins
        if (store.state.userProfile.Bruker.Admin) {
          resolve(true);
          return;
        }

        const promises = [];
        const groupDepartmentIds = [];
        user.getDepartments().then(usrDepInfo => {
          if (!usrDepInfo.departments.length) {
            // No departments? No problem
            resolve(true);
            return;
          }

          // Need to ask API for department ID for each group in scenario
          this.scenario.Grupper.forEach(group => {
            const promise = apiGroup.get(group.Gruppeid).then(res => {
              groupDepartmentIds.push(res.Avdeling);
            });
            promises.push(promise);
          });

          // When all group department IDs are in: Check if logged in user
          // have access to all groups
          Promise.all(promises).then(() => {
            const res = arrayUtil.everyItemIsPresent(
              groupDepartmentIds,
              usrDepInfo.departments
            );
            resolve(res);
          });
        });
      });
    },

    closeDetailsDialog() {
      this.dialogDetails = false;
    },

    isSmsEnabled() {
      return getChannelState(this.scenario, "Sms");
    },

    isEmailEnabled() {
      return getChannelState(this.scenario, "Epost");
    },

    isVoiceEnabled() {
      return getChannelState(this.scenario, "Tale");
    },

    getTextPreview() {
      const limit = 150;
      const createClippedText = text => {
        const dotDotDot = text.length > limit ? "..." : "";
        return text.substr(0, limit) + dotDotDot;
      };
      if (this.isSmsEnabled()) {
        return createClippedText(this.scenario.Sms.Melding);
      } else if (this.isEmailEnabled()) {
        return createClippedText(this.scenario.Epost.Melding);
      } else if (this.isVoiceEnabled()) {
        return createClippedText(this.scenario.Tale.Content);
      }
    }
  }
};

const getChannelState = (scenario, channelName) => {
  const textField = channelName === "Tale" ? "Content" : "Melding";
  return scenario[channelName] && scenario[channelName][textField];
};
</script>
<style scoped>
#card-text {
  min-height: 266px;
}
.chip-channel {
  margin-right: 6px;
}
.card-section {
  margin-bottom: 6px;
}
.alert-text-preview {
  margin-top: 6px;
}
.group-chip {
  float: left;
  margin-right: 8px;
}
</style>
